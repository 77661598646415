/* You can add global styles to this file, and also import other style files */
@import '@angular/material/prebuilt-themes/deeppurple-amber.css';
*{
  /* font-family:  DM Sans,sans-serif; */
  font-family: 'Helvetica', sans-serif;

}
.mat-dialog-container {
  background-color: #f7fafc!important;
}

@import '@angular/material/prebuilt-themes/indigo-pink.css';
.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #03c9d7!important;
}
.mat-tab-label, .mat-tab-link {
  font-family: Roboto,"Helvetica Neue",sans-serif;
  font-size: 15px!important;
  font-weight: 550!important;
}
.mat-menu-panel {
  background: #fff;
  min-height: 36px !important;
}
html, body { height: 100%; padding: 0;margin: 0;border: none;
background-image: linear-gradient(90deg,  rgb(245, 245, 245), rgb(245, 245, 245)); }
body { 
  margin: 0;
  background: #03c9d7; 
  background-color: #fafbfb!important;
}


/* .loadings {
  transform: translateY(400%);
  width: 100%;
  height: 100%;
  background: greenyellow;
  background-position: center;
  background-size: cover;

}
.spinner {
  margin: 10px auto;
  width: 60px;
  height: 50px;
  text-align: center;
}

.spinner > div {
  background-color: rgb(27, 159, 246);
  height: 100%;
  width: 6px;
  display: inline-block;
  margin-right:3px;
  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}
.spinner .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.spinner .rect3 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

.spinner .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.spinner .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

@-webkit-keyframes sk-stretchdelay {
  0%, 40%, 100% { -webkit-transform: scaleY(0.4) }
  20% { -webkit-transform: scaleY(1.0) }
}

@keyframes sk-stretchdelay {
  0%, 40%, 100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }  20% {
       transform: scaleY(1.0);
       -webkit-transform: scaleY(1.0);
     }
} */

#loading-bar .bar {
  background: #d35400;
}
#loading-bar-spinner .spinner-icon {
  border-top-color:  #d35400;
  border-left-color: #d35400;
}
#loading-bar .peg {
  -moz-box-shadow: #d35400 1px 0 6px 1px;
  -ms-box-shadow: #d35400 1px 0 6px 1px;
  -webkit-box-shadow: #d35400 1px 0 6px 1px;
  box-shadow: #d35400 1px 0 6px 1px;
}

.loading-spinner {
  -webkit-animation:spin 2s linear infinite;
  -moz-animation:spin 2s linear infinite;
  animation:spin 2s linear infinite;
}
/* Start Spinner Loader */
.spinner-background {
  height: 100%;
  width: 100%;
  min-height: 100vh;
  background-image: linear-gradient(40deg, rgba(0, 205, 241, 0.308),rgba(0, 255, 213, 0.239),rgba(0, 119, 255, 0.301));
  padding: 0;
  margin: 0;
  color: #fff;
  font: 14px "Helvetica Neue", Arial, Helvetica, Geneva, sans-serif;
  line-height: 1.5em;
  text-align: center;
  height: 100%;
  position: relative;
  overflow: hidden;
}
.spinner {
  background-color: #f56301;
  position: fixed;
  z-index: 999;
  height: 4em;
  width: 4em;
  border-radius: 5px;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  -webkit-animation: sk-rotateplane 1.2s infinite ease-in-out;
  animation: sk-rotateplane 1.2s infinite ease-in-out;
}

@-webkit-keyframes sk-rotateplane {
  0% { -webkit-transform: perspective(120px) }
  50% { -webkit-transform: perspective(120px) rotateY(180deg) }
  100% { -webkit-transform: perspective(120px) rotateY(180deg)  rotateX(180deg) }
}

@keyframes sk-rotateplane {
  0% {
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg)
  } 50% {
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg)
  } 100% {
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 4px;
  border-radius: 50px;
}

/* Track */
/* ::-webkit-scrollbar-track {
  background: #f1f1f1;
} */

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  /* background: #555; */
  background: #888;
}


.toast-msg {
  position: fixed;
  z-index: 9999;
  right: 22px;
  text-align: right; /* Align text to the right */
}

.alert-dismissible {
  padding-right: 4rem;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
  width: fit-content;
}

.alert {
  /* position: relative; */
  padding: .75rem 1.25rem;
  margin-top: -100px;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: .25rem;
  width: fit-content;
}

.alert-primary {
  color: #03c9d7 !important;
  margin-top: auto;
  width: auto;
  background-color: #fff !important;
  border-color: #03c9d7 !important;
  margin-bottom: 0px !important;
}